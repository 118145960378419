<template>
  <div class="data-level-step">
    <div class="data-level-step__container">
      <h2 class="data-level-step__headline">
        <span>What's your current level of working with data?</span>
        <span v-html="$options.filters.md(':chart_with_upwards_trend:')" />
      </h2>

      <div class="data-level-step__selector">
        <a
          href="#"
          class="data-level-step__level btn btn--selectable"
          v-for="level in Object.keys(levels)"
          v-bind:class="{ 'btn--selected': selectedLevels[level] }"
          v-bind:key="level"
          v-on:click.prevent="toggleLevel(level)"
          v-html="$options.filters.md(levels[level])" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levels: {
        "absolute_beginner": ":bulb: I'm just getting started.",
        "beginner": ":bulb::bulb: I can calculate conversion rate.",
        "intermediate": ":bulb::bulb::bulb: I'm using formulas in spreadsheets.",
        "advanced": ":bulb::bulb::bulb::bulb: I can build a BI dashboard on my own.",
        "expert": ":bulb::bulb::bulb::bulb::bulb: I know the math behind AB-test analysis."
      },
      selectedLevels: {}
    }
  },
  methods: {
    toggleLevel(level) {
      this.$set(this.selectedLevels, level, !this.selectedLevels[level]);

      this.$emit("toggle-data-level", this.selectedLevels);
    },
  }
}
</script>

.<style lang="scss">
.data-level-step {
  &__selector {
    display: flex;
    flex-direction: column;
    max-width: $px496;
  }

  &__level {
    line-height: 1.5;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: $px16;
    }
  }
}
</style>
