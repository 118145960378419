<template>
  <div class="spinner">
    <img
      v-bind:src="require('@images/shared/spinner.gif')" />
  </div>
</template>

<script>
export default {
  name: "Spinner",
}
</script>

<style>

</style>
