<template>
  <div class="subscription-pricing-plan" :class="bemModifiers">
    <div
      v-if="yearlySavingPercentage"
      class="subscription-pricing-plan__yearly-savings">
      SAVE {{ yearlySavingPercentage }}%
    </div>

    <div class="subscription-pricing-plan__name mb-32">
      {{ $t(`planName.${subscriptionPlan.billingPeriod}`).toUpperCase() }}
    </div>

    <div class="subscription-pricing-plan__price-per-month flex align-baseline mb-32">
      <div class="flex items-center mr-4">
        <div class="subscription-pricing-plan__final-price">
          {{ formattedFinalPrice }}
        </div>
      </div>

      <div class="subscription-pricing-plan__per-month flex">
        /{{ $t(`perBillingPeriod.${subscriptionPlan.billingPeriod}`) }}
      </div>
    </div>

    <div
      class="subscription-pricing-plan__plan-description mb-32"
      v-html="$options.filters.md(planDescription)">
    </div>

    <a
      class="mb-8 btn btn--fat btn--full-width"
      :class="{
        'js-open-signup-popup': isGuest
      }"
      :data-after-auth="subscriptionPlan.purchaseHref"
      :href="subscriptionPlan.purchaseHref">
      Get started
    </a>

    <div
      v-if="showOriginalStrikedPrice"
      class="subscription-pricing-plan__billing-price">
      <span
        class="mr-4 subscription-pricing-plan__billing-price--striked">
        {{ formattedOriginalPrice }}
      </span>
      {{ subscriptionPlan.finalPriceFormatted }} billed {{ $t(`billingPeriod.${subscriptionPlan.billingPeriod}`) }}
    </div>
  </div>
</template>

<script>
import currency from "currency.js";
import { mapGetters } from "vuex";

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters("user", ["loggedIn", "isGuest"]),
    bemModifiers() {
      return this.modifiers.map(m => {
        return `subscription-pricing-plan--${m}`
      }).join(" ");
    },
    planDescription() {
      return this.$t(`planDescription.${this.subscriptionPlan.billingPeriod}`, { yearlySavingPercentage: this.yearlySavingPercentage });
    },
    yearlySavingPercentage() {
      if (!this.finalMonthlyPrice) {
        return null;
      }

      if (this.subscriptionPlan.billingPeriod !== "year") {
        return null;
      }

      const monthlyPricePerYear = 12 * this.finalMonthlyPrice;
      const yearlyPrice = this.subscriptionPlan.finalPrice;

      return Math.floor(100.0 * (monthlyPricePerYear - yearlyPrice) / monthlyPricePerYear);
    },
    formattedOriginalPrice() {
      if (this.subscriptionPlan.billingPeriod === "year") {
        const currency = this.subscriptionPlan.currency;
        const monthlyPricePerYear = 12 * this.originalMonthlyPrice;

        return currencyFormatter[currency](monthlyPricePerYear);
      } else {
        return this.subscriptionPlan.originalPriceFormatted;
      }
    },
    formattedFinalPrice() {
      if (this.subscriptionPlan.billingPeriod === 'year') {
        const currency = this.subscriptionPlan.currency;
        const yearlyPricePerMonth = this.subscriptionPlan.finalPrice / 12.0;

        return currencyFormatter[currency](yearlyPricePerMonth);
      } else {
        return this.subscriptionPlan.finalPriceFormatted;
      }
    },
    showOriginalStrikedPrice() {
      return this.subscriptionPlan.hasDiscount || this.subscriptionPlan.billingPeriod === "year";
    }
  },
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    modifiers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    finalMonthlyPrice: {
      type: Number,
      default: null
    },
    originalMonthlyPrice: {
      type: Number,
      default: null
    }
  },
  i18n: {
    messages: {
      en: {
        planName: {
          day: "Daily",
          week: "Weekly",
          month: "Monthly",
          year: "Yearly"
        },
        perBillingPeriod: {
          day: "day",
          week: "week",
          month: "mo",
          year: "mo"
        },
        planDescription: {
          month: "Monthly plan has **all premium features**. It's ideal for brushing up your Data Analysis and SQL knowledge or prepare for an upcoming interview.",
          year: "Yearly plan **saves you %{yearlySavingPercentage}%** (compared to the monthly plan) and is perfect for taking the whole SQL Habit Course, preparing for an interview and building a strong data portfolio on Github."
        },
        billingPeriod: {
          day: "daily",
          week: "weekly",
          month: "monthly",
          year: "once a year"
        },
      }
    }
  }
}
</script>

<style lang="scss">
.subscription-pricing-plan {
  $root: &;

  position: relative;
  padding: $px24;

  border-radius: $px8;

  background-color: $grey-10;

  &__name {
    @include h3;
    font-weight: 700;
  }

  &__final-price {
    font-size: $px32;
    line-height: $px32;
    font-weight: 700;
  }

  &__per-month {
    margin-top: auto;

    font-size: $px20;
    line-height: 1;
  }

  &__billing-price {
    margin-top: $px8;

    @include font-small;
    text-align: center;

    &--striked {
      color: $grey-2;
      text-decoration: line-through;
    }
  }

  &__yearly-savings {
    position: absolute;
    right: $px16;
    top: 0;
    transform: translateY(-50%);
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $purple-5;

    color: $white;
    font-size: 15px;
    font-weight: 700;
  }
}
</style>
