<template>
  <div class="set-goal-step">
    <div class="set-goal-step__container">
      <h2 class="set-goal-step__headline flex align-center">
        <span class="mr-8">Goal setting</span>
        <span class="flex" v-html="$options.filters.md(':dart:')" />
      </h2>

      <div class="set-goal-step__description">
        How Data Analysis with SQL will be useful for you? <br class="hidden-xs"> What do you want to achieve?
      </div>

      <div class="set-goal-step__goals">
        <a
          href="#"
          class="set-goal-step__goal btn btn--selectable"
          v-bind:class="{ 'btn--selected': goalOptionClass(goal) }"
          v-for="goal in Object.keys(availableGoals)"
          v-bind:key="goal"
          v-on:click.prevent="toggleGoal(goal)">

          {{ availableGoals[goal] }}
        </a>

        <input
          type="text"
          placeholder="Other"
          name="other-goal"
          class="set-goal-step__goal-input" data-value="other" v-model="otherGoal"
          v-on:change="handleOtherInput">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      availableGoals: {
        "at-work": "Build my own dashboards at work",
        "job-interview": "Get ready for an Interview",
        "business": "Measure my own business"
      },
      otherGoal: null,
      goals: {}
    }
  },
  methods: {
    goalOptionClass(goal) {
      return this.goals[goal];
    },
    toggleGoal(goal) {
      this.$set(this.goals, goal, !this.goals[goal]);

      this.$emit("toggle-set-goals", this.goals);
    },
    handleOtherInput() {
      this.$emit("toggle-other-goal", this.otherGoal);
    }
  }
}
</script>

<style lang="scss">
.set-goal-step {
  max-width: 720px;

  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $px32;
  }

  &__goal {
    margin-bottom: $px16;

    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__goal-input {
    margin-bottom: $px16;
    padding: $px8;

    outline: none;
    appearance: none;
    border: 0;
    border-bottom: $px1 solid $grey-9;

    @include font-btn;
    color: $grey-1;
  }
}
</style>
