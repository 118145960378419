import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import TeamSubscriptionSizeCalculator from "components/team-subscription-size-calculator.vue"
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";
import { store } from "store";
import Sentry from "modules/sentry";

setCouponCookie();

const $container = document.querySelector(".js-team-subscription-size-calculator");

if($container) {
  axios.get("/api/subscription-prices.json", {
    params: {
      user_scope: "team"
    }
  }).then(response => {
    const subscriptionPlans = response.data.subscriptionPlans;
    const teamSubscriptionPlan = subscriptionPlans.find(p => p.userScope === "team");

    new Vue({
      el: $container,
      store,
      i18n: new VueI18n({
        locale: "en",
        silentTranslationWarn: process.env.NODE_ENV === "production"
      }),
      render: h => h(TeamSubscriptionSizeCalculator, {
        props: {
          subscriptionPlan: teamSubscriptionPlan
        }
      })
    });

    const $spinner = document.querySelector(".js-team-subscription-pricing-plans-spinner");
    if($spinner) {
      $spinner.remove();
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
