<template>
<div class="reviews">
  <h2 class="reviews__headline">
    Reviews
  </h2>

  <div class="reviews__rating flex align-center">
    <ReviewStars
      class="mr-8"
      :rating="averageRating" />

    <span class="mr-8">
      {{ averageRating }}
    </span>

    <a
      href="/reviews"
      target="_blank">
      {{ reviewsCount }} reviews
    </a>
  </div>

  <div class="reviews__items">
    <div
      v-for="(review, index) in reviews"
      :key="index"
      class="reviews__item">
      <div
        class="reviews__feedback"
        v-html="review.feedback" />

      <div class="reviews__author">
        {{ review.author }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import ReviewStars from "components/review-stars";

export default {
  name: "Reviews",
  components: {
    ReviewStars
  },
  data() {
    return {
      averageRating: parseFloat(window.stats.averageRating, 10),
      reviewsCount: window.stats.reviewsCount,
      reviews: []
    }
  },
  mounted() {
    axios.get("/api/reviews.json", {}).then(response => {
      const reviews = response.data.reviews;

      this.reviews = reviews;
    });
  }
}
</script>

<style>

</style>
