import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import SubscriptionPricingPlan from "components/subscription-pricing-plan";
// TODO: figure out subscription plans promo banner
import PromoBanner from "components/promo-banner";
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";
import Sentry from "modules/sentry";
import { store } from "store";

Vue.use(VueI18n);

setCouponCookie();

const $containers = document.querySelectorAll(".js-subscription-pricing-plan");
const billingPeriods = Array.from($containers).map(el => el.dataset.billingPeriod);
const urlParams = new URLSearchParams(window.location.search);

if(billingPeriods.length > 0) {
  axios.get("/api/subscription-prices.json", {
    params: {
      billing_periods: billingPeriods,
      pr: urlParams.get("ref")
    }
  }).then(response => {
    const subscriptionPlans = response.data.subscriptionPlans;
    const monthlyPlan = subscriptionPlans.find(p => p.billingPeriod === "month");

    $containers.forEach($container => {
      let billingPeriod = $container.dataset.billingPeriod;

      const subscriptionPlan = subscriptionPlans.find(p => p.billingPeriod === billingPeriod);
      const modifiers = String($container.dataset.modifiers).split(",");

      new Vue({
        el: $container,
        i18n: new VueI18n({
          locale: "en"
        }),
        store,
        render: h => h(SubscriptionPricingPlan, {
          props: {
            subscriptionPlan,
            modifiers,
            finalMonthlyPrice: monthlyPlan.finalPrice,
            originalMonthlyPrice: monthlyPlan.originalPrice
          }
        })
      });
    });

    const $spinner = document.querySelector(".js-subscription-pricing-plans-spinner");
    if($spinner) {
      $spinner.remove();
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
