<template>
  <div>
    <svg class="rating-star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <g id="icon-star">
          <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
                  l11.547-1.2L16.026,0.6L20.388,10.918z" />
        </g>

        <linearGradient id="halfGradient">
          <stop stop-opacity="1" :offset="starRatingBgOffset" stop-color="#F9D423"></stop>
          <stop stop-opacity="0" :offset="starRatingBgOffset"></stop>
        </linearGradient>
      </defs>
    </svg>

    <svg class="star-rating" viewBox="0 0 180 32">
      <use xlink:href="#icon-star" x="0" y="0" :class="ratingStarClass(0)" />
      <use xlink:href="#icon-star" x="36" y="0" :class="ratingStarClass(1)" />
      <use xlink:href="#icon-star" x="72" y="0" :class="ratingStarClass(2)" />
      <use xlink:href="#icon-star" x="108" y="0" :class="ratingStarClass(3)" />
      <use xlink:href="#icon-star" x="144" y="0" :class="ratingStarClass(4)" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ReviewStars",
  computed: {
    starRatingBgOffset() {
      return Math.round(100 * (this.rating % 1.0));
    }
  },
  methods: {
    ratingStarClass(ratingIndex) {
      if(this.rating - ratingIndex < 0) {
        return "rating-star--empty"
      } else if (this.rating - ratingIndex < 1) {
        return "rating-star--half"
      }
    }
  },
  props: {
    rating: Number
  }
}
</script>

<style>

</style>
