<template>
<div class="companies companies--white-bg">
  <div class="companies__container">
    <h2 class="companies__headline">Employees of these companies study on SQL Habit</h2>

    <div class="companies__logos">
      <a class="companies__logo-container" href="https://www.blinkist.com/" target="_blank" rel="nofollow">
        <BlinkistLogo class="companies__logo companies__logo--blinkist" />
      </a>

      <a class="companies__logo-container" href="https://careerfoundry.com/" target="_blank" rel="nofollow">
        <CfLogo class="companies__logo companies__logo--cf" />
      </a>

      <a class="companies__logo-container" href="https://feather-insurance.com/" target="_blank" rel="nofollow">
        <FeatherLogo class="companies__logo companies__logo--feather" />
      </a>

      <a class="companies__logo-container" href="https://relive.cc/" target="_blank" rel="nofollow">
        <ReliveLogo class="companies__logo companies__logo--relive" />
      </a>

      <a class="companies__logo-container" href="https://phiture.com/" target="_blank" rel="nofollow">
        <PhitureLogo class="companies__logo companies__logo--phiture" />
      </a>

      <a class="companies__logo-container" href="https://www.papumba.com/" target="_blank" rel="nofollow">
        <ModoLogo class="companies__logo companies__logo--modo" />
      </a>
    </div>
  </div>
</div>
</template>

<script>
import BlinkistLogo from "@images/companies_logos/blinkist.svg";
import CfLogo from "@images/companies_logos/cf.svg";
import FeatherLogo from "@images/companies_logos/feather.svg";
import ReliveLogo from "@images/companies_logos/relive.svg";
import PhitureLogo from "@images/companies_logos/phiture.svg";
import ModoLogo from "@images/companies_logos/modo.svg";

export default {
  name: "ClientCompanies",
  components: {
    BlinkistLogo,
    CfLogo,
    FeatherLogo,
    ReliveLogo,
    PhitureLogo,
    ModoLogo,
  }
}
</script>

<style>

</style>
