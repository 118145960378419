import Vue from "vue/dist/vue.esm";
import ReceiptCustomerInfo from "components/receipt-customer-info";

const $receiptPage = document.querySelector(".purchase-receipt-page");

if($receiptPage) {
  const $customerInfo = document.querySelector(".js-receipt-customer-info");

  const { customerInfo, updateReceiptCustomerEndpoint } = $customerInfo.dataset;

  new Vue({
    el: $customerInfo,
    render: h => h(ReceiptCustomerInfo, {
      props: {
        infoInput: customerInfo,
        updateReceiptCustomerEndpoint
      }
    })
  });
}
