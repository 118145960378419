export const toggleLoggedInElements = (isGuest) => {
  if (isGuest === true) {
    return;
  }
  // Treat logged-in visitors.
  // Show elements for logged out users.
  // Hide elements for logged in users.

  document.querySelectorAll(".js-open-signup-popup").forEach(el => {
    el.classList.remove("js-open-signup-popup");
  });

  document.querySelectorAll(".js-remove-logged-in").forEach(el => {
    el.remove();
  });

  document.querySelectorAll(".js-show-logged-in").forEach($el => {
    const activeClass = $el.dataset.activeClass || "active";

    $el.classList.add(activeClass);
  });

  document.querySelectorAll("a.js-logged-in-href").forEach($a => {
    const { loggedInHref } = $a.dataset;

    if(loggedInHref) {
      $a.href = loggedInHref;
    }
  });

  document.querySelectorAll(".js-hidden-logged-in").forEach($el => {
    $el.classList.add("hidden");
  });

  document.querySelectorAll("[data-logged-in-class-name]").forEach($el => {
    const className = $el.dataset.loggedInClassName;
    $el.classList.add(className);
  });
};

export const toggleLoggedOutElements = (isGuest) => {
  if (isGuest === false) {
    return;
  }
  // Treat logged-out aka "guest" visitors.
  // Show elements for logged in users.
  // Hide elements for logged out users.

  document.querySelectorAll(".js-hidden-logged-in").forEach(el => {
    el.classList.remove("js-hidden-logged-in");
    el.classList.remove("hidden");
  });
};

export const toggleCustomerElements = (userSegment) => {
  if (userSegment !== "customer") {
    return;
  }

  document.querySelectorAll("[data-hidden-for='customer']").forEach(el => {
    el.remove();
  });

  document.querySelectorAll("[data-visible-for='customer']").forEach(el => {
    el.classList.add("active");
  });
}
