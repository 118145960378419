<template>
<div class="blob-hero relative">
  <component
    class="blob-hero__left-blob"
    :is="require(`@images/svgs/blobs/${leftBlob}.svg`)" />

  <h1 class="blob-hero__headline">
    {{ headline }}
  </h1>

  <div class="blob-hero__description">
    {{ subheadline }}
  </div>

  <component
    class="blob-hero__right-blob"
    :is="require(`@images/svgs/blobs/${rightBlob}.svg`)" />
</div>
</template>

<script>
export default {
  name: "BlobHero",
  props: ["headline", "subheadline", "leftBlob", "rightBlob"]
}
</script>

<style lang="scss">
.blob-hero {
  position: relative;

  text-align: center;

  @include breakpoint($md) {
    padding: $px48;
  }

  &__headline {
    margin: 0 0 $px24;
    position: relative;
    z-index: 2;
  }

  &__description {
    margin-bottom: $px24;
    position: relative;
    z-index: 2;

    @include font-large;

    ul {
      margin: $px12 0 0;
      padding: 0;

      list-style: none;
    }
  }

  &__left-blob {
    position: absolute;
    left: 0;
    top: -$px48;
    z-index: -1;

    path {
      fill: $yellow-9;
    }
  }

  &__right-blob {
    width: $px144;
    position: absolute;
    right: -$px24;
    bottom: -$px48;
    z-index: -1;

    path {
      fill: $blue-9;
    }
  }
}
</style>
