<template>
  <div class="newsletter-step">
    <div class="newsletter-step__container">
      <h2 class="newsletter-step__headline">
        <span>Customize your SQL Habit Newsletter</span>
        <span v-html="$options.filters.md(':email:')" />
      </h2>

      <div
        class="newsletter-step__description"
        v-html="$options.filters.md(`You'll receive a minimalistic, **biweekly** email:`)">
      </div>

      <label
        href="#"
        class="newsletter-step__newsletter "
        v-for="newsletter in Object.keys(newsletters)"
        v-bind:key="newsletter"
        v-on:click.prevent="toggleLevel(newsletter)">
        <div
          class="newsletter-step__checkbox"
          v-bind:class="{ 'newsletter-step__checkbox--checked': selectedNewsletters[newsletter] }">
          <CheckedIcon
            v-if="selectedNewsletters[newsletter]"
            class="newsletter-step__checkbox-icon" />
        </div>
        <span
          v-html="$options.filters.md(newsletters[newsletter])" />
      </label>
    </div>
  </div>
</template>

<script>
import CheckedIcon from "@images/font_awesome/check-light.svg";

export default {
  components: {
    CheckedIcon
  },
  data() {
    return {
      newsletters: {
        "sql_habit_news": ":newspaper: SQL Habit sales, new features and content",
        "data_news": ":bar_chart: Practical blog posts and tutorials",
        "jobs": ":briefcase: Digest of new job ads"
      },
      selectedNewsletters: {}
    }
  },
  methods: {
    toggleLevel(newsletter) {
      this.$set(this.selectedNewsletters, newsletter, !this.selectedNewsletters[newsletter]);

      this.$emit("toggle-newsletter", this.selectedNewsletters);
    },
  }
}
</script>

.<style lang="scss">
.newsletter-step {
  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__newsletter {
    display: flex;
    max-width: $px496;

    font-size: 18px;
    line-height: 1.5;
    text-align: left;

    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: $px16;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $px24;
    height: $px24;
    flex-shrink: 0;
    margin-right: $px16;

    border-radius: $px4;

    background-color: $grey-9;

    &--checked {
      background-color: $blue-9;
    }
  }

  &__checkbox-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
