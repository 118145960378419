import Vue from "modules/vue_twemoji";
import Notification from "components/notification.vue";

export const notify = ({ message, type, timeout = true, enableSticky = true }) => {
  if (!message) {
    return;
  }

  // Drop Rails notifications just in case
  document.querySelectorAll(".notice").forEach(el => el.remove());

  const $notificationsContainer = document.querySelector(".vue-notifications-container");
  const $notification = document.createElement("div");

  $notification.classList = "vue-notification";

  $notificationsContainer.innerHTML = "";
  $notificationsContainer.appendChild($notification);

  new Vue({
    el: $notification,
    render: h => h(Notification, {
      props: {
        message,
        type,
        timeout,
        enableSticky
      }
    })
  });
};
