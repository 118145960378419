<template>
<div class="faq">
  <h2 class="faq__headline">
    {{ $t("headline") }}
  </h2>

  <div class="faq__items">
    <div
      v-for="question in questions"
      :key="question.question"
      class="faq__item">
      <div
        class="faq__question"
        v-html="$options.filters.mdWithP(question.question)" />

      <div
        class="faq__answer"
        v-html="$options.filters.mdWithP(question.answer)" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    extraQuestionsSlug: {
      type: String,
      default: null
    }
  },
  computed: {
    questions() {
      let result = this.$t("questions.default");

      if (this.extraQuestionsSlug) {
        result = [
          ...this.$t(`questions.${this.extraQuestionsSlug}`),
          ...result
        ];
      }

      return result;
    }
  },
  i18n: {
    messages: {
      en: {
        headline: "Frequently Asked Questions",
        questions: {
          default: [
            {
              question: "Can I try SQL Habit for free?",
              answer: "Absolutely. There're %{freeLessonsCount} free course lessons and %{freePracticeExercisesCount} practice exercises. Just sign up with your email, no credit card info required."
            },
            {
              question: "Do you accept PayPal purchases?",
              answer: "Of course! :100: "
            },
            {
              question: "Can I get an invoice?",
              answer: "Absolutely! Right after purchasing you'll get a receipt which includes a link to generate an invoice with any extra information you need to add for your own accounting purposes."
            },
            {
              question: "Can I purchase SQL Habit for my team/company?",
              answer: "Sure thing! <a href='/teams'>Check out team options here</a> or shoot me an email with how many licenses do you want at <a href='mailto:hello@sqlhabit.com'>hello@sqlhabit.com</a> and I'll send you an invoice. :busts_in_silhouette: "
            },
            {
              question: "What if I realize it's not for me?",
              answer: "No problem! There's a 14-day money back guarantee on all purchases. Ping me at <a href=\"mailto:support@sqlhabit.com\">support@sqlhabit.com</a> and you'll be refunded in full, no questions asked."
            }
          ],
          team: [
            {
              question: "How can I invite my team?",
              answer: "After purchasing a number of licenses you'll get an invitation link."
            },
            {
              question: "How can I monitor team's progress?",
              answer: "You'll have access to a progress dashboard or you can always reach out to <a href=\"mailto:support@sqlhabit.com\">support@sqlhabit.com</a>."
            },
            {
              question: "My company has special requirements for purchasing software.",
              answer: "No problem at all, reach out to <a href=\"mailto:support@sqlhabit.com\">support@sqlhabit.com</a> and we'll sort everything out swiftly."
            }
          ],
          giftcard: [
            {
              question: "How gift card is delivered?",
              answer: "After purchasing, you'll receive a redemption link to share with your friend."
            }
          ],
          subscriptions: [
            {
              question: "How easy is it to cancel my subscription?",
              answer: "You'll receive an email 72 hours before your next subscription cycle. You can also cancel your subscription from <a href=\"/users/settings\">the profile page</a>."
            }
          ]
        }
      }
    }
  }
}
</script>

<style>

</style>
