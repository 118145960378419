import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import Onboarding from "components/onboarding";
import { store } from "store";

Vue.use(VueI18n);

const $onboardingContainer = document.querySelector(".js-onboarding");

if($onboardingContainer) {
  new Vue({
    i18n: new VueI18n({
      locale: "en"
    }),
    store,
    el: $onboardingContainer,
    render: h => h(Onboarding)
  });
}
