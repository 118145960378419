<template>
  <div class="sql-level-step">
    <div class="sql-level-step__container">
      <h2 class="sql-level-step__headline">
        <span>How familiar are you with SQL?</span>
        <span v-html="$options.filters.md(':bar_chart:')" />
      </h2>

      <div class="sql-level-step__selector">
        <a
          href="#"
          class="sql-level-step__level btn btn--selectable"
          v-for="level in Object.keys(levels)"
          v-bind:class="{ 'btn--selected': selectedLevels[level] }"
          v-bind:key="level"
          v-on:click.prevent="toggleLevel(level)"
          v-html="$options.filters.md(levels[level])" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levels: {
        "zero": ":bulb: I'm just getting started.",
        "beginner": ":bulb::bulb: I can filter and sort records.",
        "intermediate": ":bulb::bulb::bulb: I know how to group records.",
        "advanced": ":bulb::bulb::bulb::bulb: I know at least 3 kinds of JOIN-s.",
        "expert": ":bulb::bulb::bulb::bulb::bulb: I can explain window functions to anyone."
      },
      selectedLevels: {}
    }
  },
  methods: {
    toggleLevel(level) {
      this.$set(this.selectedLevels, level, !this.selectedLevels[level]);

      this.$emit("toggle-sql-level", this.selectedLevels);
    },
  }
}
</script>

.<style lang="scss">
.sql-level-step {
  &__selector {
    display: flex;
    flex-direction: column;
    max-width: $px496;
  }

  &__level {
    line-height: 1.5;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: $px16;
    }
  }
}
</style>
