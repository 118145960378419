<template>
  <div class="introduction-step">
    <div class="introduction-step__container">
      <h2
        class="introduction-step__headline"
        v-html="$options.filters.md(`You're all set up :white_check_mark:`)">
      </h2>

      <div
        class="introduction-step__description"
        v-html="$options.filters.md(`You'll develop data and SQL skills with **300+ real life scenarios** from Marketing or Product Management, learning about business metrics, technical details of data tracking and much more.`)">
      </div>

      <div class="introduction-step__snapshot">
        <img
          class="introduction-step__snapshot-image"
          :src="require('@images/onboarding/exercise@2x.png')"
          :srcset="require('@images/onboarding/exercise.png') + ' 1x, ' + require('@images/onboarding/exercise@2x.png') + ' 2x'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.introduction-step {
  max-width: 720px;

  &__description {
    margin-bottom: $px16;

    @include font-large;
  }

  &__snapshot-image {
    width: 100%;
    max-width: 426px;
    max-height: 361px;

    @include breakpoint($sm) {
      width: 426px;
      height: 361px;
    }
  }
}

</style>
